const PROD_API_URL = 'https://api.timego.com';
const STAGE_API_URL = 'https://staging-api.timego.com';

const localCfg = {
  API_URL: STAGE_API_URL,
};

const stageCfg = {
  API_URL: STAGE_API_URL,
};

const prodCfg = {
  API_URL: PROD_API_URL,
};

export const isLocal = process.env.NODE_ENV === 'development';
export const isStage = /staging/.test(document.location.hostname);

const getCurrentConfig = () => {
  if (isLocal) {
    return localCfg;
  }

  if (isStage) {
    return stageCfg;
  }

  return prodCfg;
};

export const { API_URL } = getCurrentConfig();

export const ENDPOINTS = {
  /* AUTH ENDPOINTS */
  signIn: '/auth',
  signUp: '/users',
  signUpFormLanding: '/users/signup-form',
  signOut: '/auth/logout',
  refreshToken: '/auth/tokens',
  forgotWebAddress: 'auth/forgot-web-address',
  forgotPassword: '/auth/forgot-password',
  recoverPassword: '/auth/recover-password',
  recoverToken: '/auth/verify-password-recovery-token',
  twoFactorCode: '/two-factor-code',
  signInWithTwoFactor: '/two-factor-challenge',
  adminUsers: '/admin/users/',
  logoutByAdmin: '/logout',

  /* INDUSTRIES ENDPOINTS */
  industries: '/industries',

  /* USERS ENDPOINTS */
  currentUser: '/users/me',
  verifyEmail: '/users/verify-email',
  resendVerificationEmail: '/users/me/email-verification',
  checkToken: '/users/validate-token',
  interfaceSettings: '/users/me/interface-settings',
  mostVisitedPages: '/users/me/interface-settings/navigation-history',

  /* EMPLOYEES ENDPOINTS */
  employees: '/employees',
  importEmployees: '/employees/import',
  parseEmployees: '/employees-csv/parse',
  exportEmployees: '/employees-csv/export',
  isRestricted: '/isRestricted',

  /* EMPLOYEES RATES ENDPOINTS */
  rates: '/rates',

  /* EMPLOYEES LEAVE REQUESTS ENDPOINTS */
  leave: '/leave-requests',

  /* EMPLOYEES PERFORMANCE LOGS ENDPOINTS */
  perfLogs: '/performance-logs',

  /* GROUPS ENDPOINTS */
  groups: '/employee-groups',

  /* DOCS ENDPOINTS */
  docs: '/employee-files',

  /* TO-DO ENDPOINTS */
  todo: '/todo',
  tasks: '/tasks',

  /* SCHEDULE ENDPOINTS */
  schedule: '/shifts',
  copyShift: '/copy',
  notify: '/notify',
  timeEntries: '/time-entries',
  timeEntriesLogs: '/time-entry-logs',
  lateCallOff: '/late-call-off',
  noShow: '/no-show',
  clockOut: '/clock-out',
  emptyJobForSchedule: '/schedule-jobs',

  /* SCHEDULE ENDPOINTS  (WEB SOCKET)*/
  scheduleWeb: '/schedule-web',

  /* COMPANIES ENDPOINTS */
  companies: '/companies',
  companyImg: '/companies/my/company-image',
  companySettings: '/companies/my/settings',
  companyPayroll: '/companies/my/settings/payroll',
  companyBusiness: '/companies/business/accountName',
  companyReport: '/companies/export',

  /* COMPANIES ENDPOINTS */
  actionLogs: '/action-logs',
  revert: '/revert',

  /* CONTACTS ENDPOINTS */
  contacts: '/contacts',

  /* LOCATIONS ENDPOINTS */
  locations: '/locations',
  locationDocs: '/location-docs',

  /* JOBS ENDPOINTS */
  jobs: '/jobs',
  jobsGroups: '/job-groups',
  jobRatesAll: '/job-rates',
  jobRates: '/rates',
  jobLogs: '/job-logs',
  jobExpenses: '/job-expenses',
  status: '/status',

  /* MAP ENDPOINTS */
  map: '/map',

  /* CHECKPOINT */
  checkpointsSettings: '/checkpoint-settings',
  checkpoints: '/checkpoints',
  scans: '/checkpoint-scans',
  incidentTypes: '/incident-types',
  copyIncidentType: '/incident-types/copy',
  parkingIssueTypes: '/parking-issue-types',
  incidents: '/incidents',
  parkingIssues: '/parking-issues',
  checkpointsQRCodes: '/checkpoints/qr-codes',

  /* CALENDAR */
  events: '/events',

  /* FORMS */
  forms: '/forms',
  results: '/results',
  resultsReport: '/results-report',
  images: '/images',
  public: '/public',
  files: '/files',
  download: '/download',
  analysis: '/analysis',

  /* ACTIVITIES */
  activities: '/activities',

  /* ACTIVITIES (SOCKET)*/
  activity: '/activity',

  /* SUBSCRIPTION*/
  subscriptions: '/subscriptions',
  userSubscription: '/subscriptions/my',
  paymentInformation: '/payment-information',
  addSubscription: '/subscriptions/checkout-session',

  /* PAYMENTS*/
  payments: '/payments',
  billedUsers: '/billed-users',

  /* PAYROLLS*/
  payrolls: '/payrolls',
  schedules: '/schedules',
  details: '/details',
  periods: '/periods',
  years: '/years',
  payrollAdjustment: '/payroll-adjustments',

  /* HOLIDAYS*/
  holidays: './holidays',

  /* REPORT*/
  reports: '/reports',
  timeOutsideGeofence: '/time-outside-geofence',

  /* EQUIPMENT*/
  equipment: '/equipment',
  equipmentTypes: '/equipment/types',
  equipmentItems: '/equipment/items',
  equipmentLogs: '/equipment-logs',
  equipmentExpenses: '/equipment-expenses',
  conditionItems: '/equipment/conditions',

  /* INVOICE*/
  invoiceSettings: '/invoice-settings',
  invoiceNumber: '/invoice-settings/invoice-number',
  recurringInvoices: '/recurring-invoices',
  invoices: '/invoices',
  invoiceLines: '/invoices/lines',
  invoicesHours: '/invoices/timeline/shifts-hours',
  invoiceCalculate: '/invoices/calculate',
  sentStatus: '/sent-status',
  generate: '/generate',
  unInvoicedHours: '/uninvoiced-hours',
  markedInvoiced: '/mark-invoiced',
  emailsHistory: '/email-reads',
  invoiceAging: '/aging-invoices',
  statement: '/statement',

  /* FEEDBACK*/
  feedback: '/feedback',

  /* IMPLEMENTATION PLANS*/
  implementationPlans: '/implementation-plan-items',
  modifyOrder: '/move/order',

  pdf: '/pdf',
  types: '/types',
  emails: '/emails',

  /* TEST*/
  test: '/test',

  /* LOCATION_RATINGS*/
  locationRatings: '/location-ratings',
  response: '/responses',

  /* INTEGRATION*/
  createIntegration: '/integrations/qboIntegration/connection',
  createXeroIntegration: '/integrations/xeroIntegration/connection',
  getIntegration: '/integrations',
  getConfiguration: '/integrations/xeroIntegration',
  getEarningRatesAndPayRunCalenders:
    '/integrations/xeroIntegration/configuration/earningRatesAndPayRunCalenders',
  syncEarningRatesAndPayCalenders:
    '/integrations/xeroIntegration/configuration/earningRatesAndPayRunCalendersFromXero',
  createConfiguration: '/integrations/xeroIntegration/configuration',
  getAuthURLIntegration: '/integrations/AuthURL',
  deleteIntegration: '/integrations/qboIntegration/connection',
  deleteXeroIntegration: '/integrations/xeroIntegration/connection',
  getReports: '/reports/time-activity',
  verifyRates: '/integrations/qboIntegration/checkRates',
  syncTimeActivity: '/integrations/qboIntegration/timeActivity',
  syncPayrollXero: '/integrations/xeroIntegration/sync',
  updateRates: '/integrations/qboIntegration/updateRates',
  getSyncLogs: '/integrations/qboIntegration/logs',
};
